import { Client, Account, Databases, Functions, ID, Query, Locale} from "appwrite";
import { askAgainTextMarkdown, convertDataToAiWriteMsg, convertDataToAppWriteMsg, getLocationInfo, messageOnlyForPro, messagesLimitText } from "../utils";
import axios from "axios";


class AppWriteBackend {
    private client: Client;
    private account: Account;
    private database: Databases;
    private locale: Locale;
    private functions: Functions;
    private subscriptions: any[];
    private aiassistents: any[];


    constructor() {
        this.client = new Client()
            .setEndpoint(process.env.REACT_APP_API_ENDPOINT) // Your API Endpoint
            .setProject(process.env.REACT_APP_API_PROJECT_ID);         // Your project ID

        this.account = new Account(this.client);
        this.database = new Databases(this.client);
        this.functions = new Functions(this.client);
        this.locale = new Locale(this.client);
        this.subscriptions = [];

       this.getAIAssistents().then(response => console.log("aiassistents", response));
        
        this.getUserInfo().then(response => setLoggeedInUser(response)).catch(error => localStorage.removeItem("authUser"));
    }

    subscribe = (path, callback) => {
        return this.client.subscribe(path, callback);
    }

    /**
   * Handle the error
   * @param {*} error
   */
    _handleError(error) {
        // var errorCode = error.code;
        var errorMessage = error.message;
        return "Error : " + errorMessage;
    }

    updatePhoneNumber = (phoneNumber, password) => {
        return new Promise((resolve, reject) => {
            this.account.updatePhone(`+${phoneNumber}`, password)
                .then(response => {
                    this.verificationPhoneNumber()
                        .then(response => resolve(response))
                })
                .catch(error => reject(this._handleError(error)));

            this.account.get().then((response) => {
                this.database.updateDocument("kapygpt", "user", response.$id, { phoneNumber: `+${phoneNumber}` })
                    .then(response => console.log("conversation created")).catch(error => console.log("error", error));
            })
        });
    }

    verificationPhoneNumber = () => {
        return new Promise((resolve, reject) => {
            this.account.createPhoneVerification()
                .then(response => resolve(response))
                .catch(error => reject(this._handleError(error)));
        });
    }

    verificationPhoneNumberConfirmed = (userId, secretKey) => {
        return new Promise((resolve, reject) => {
            this.account.updatePhoneVerification(userId, secretKey)
                .then(response => resolve(response))
                .catch(error => reject(this._handleError(error)));
        });
    }

    signInWithPhoneNumber = (phoneNumber) => {
        return new Promise((resolve, reject) => {
            this.account.createPhoneSession(ID.unique(), `+${phoneNumber}`)
                .then(response => resolve(response))
                .catch(error => reject(this._handleError(error)));
        });
    }

    signInWithPhoneNumberConfirmed = (userId, secretKey) => {
        return new Promise((resolve, reject) => {
            this.account.updatePhoneSession(userId, secretKey)
                .then(response => resolve(response))
                .catch(error => reject(this._handleError(error)));
        });
    }

    getUserInfo = () => {
        return new Promise((resolve, reject) => {
            this.account.get()
                .then(response => {
                    setLoggeedInUser(response)
                    resolve(response)
                })
                .catch(error => {
                    localStorage.removeItem("authUser");
                    reject(this._handleError(error))
                });
        });
    }

    /**
    * Registers the user with given details
    */
    registerUser = (email, password, displayName) => {
        // call login user function
        return new Promise((resolve, reject) => {
            this.account.create(ID.unique(), email, password, displayName)
                .then(response => {
                    this.database.createDocument(
                        'kapygpt',
                        'location',
                        response?.$id,
                        {
                            "userId": response?.$id,
                        }
                    ).then(location => {
                        console.log("location created", location)
                        console.log("response", response)
                        this.initUserData(response)
                        this.loginUser(email, password)
                            .then(response => resolve(response))
                    }).catch(error => reject(this._handleError(error)));
                })
                .catch(error => reject(this._handleError(error)));
        });
    };

    /**
    * Login user with given details
    */
    loginUser = (email, password) => {
        return new Promise((resolve, reject) => {
            this.account.createEmailSession(email, password)
                .then(response => { 
                    this.updateUserData(response)
                    setLoggeedInUser(response)
                    resolve(response);  
                })
                .catch(error => reject(this._handleError(error)));
        });
    }

    /**
     * Logout the user
     */
    logoutUser = () => {
        return new Promise((resolve, reject) => {
            this.account.deleteSession('current')
                .then(response => resolve(response))
                .catch(error => reject(this._handleError(error)));
            localStorage.removeItem("authUser");
        });
    }

    /**
 * forget Password user with given details
 */
    forgetPassword = email => {
        return new Promise((resolve, reject) => {
            this.account.createRecovery(email, "https://kapygpt.kapygenius.com/auth-changepassword")
                .then(response => resolve(response))
                .catch(error => reject(this._handleError(error)));
        });
    };

    /**
     * change Password user with given details
     */
    changePassword = (userId, secret, password, confirmPassword) => {
        return new Promise((resolve, reject) => {
            this.account.updateRecovery(userId, secret, password, confirmPassword)
                .then(response => resolve(response))
                .catch(error => reject(this._handleError(error)));
        });
    }

    /**
     * Returns the datas of the authenticated user
     */
    getUserData = (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                const userAuth: any = await this.getUserInfo();
                const userData: any = await this.database.getDocument("kapygpt", "user", userAuth.$id);
                resolve(userData);
            } catch (error) {
                localStorage.removeItem("authUser");
                reject(this._handleError(error));
            }
        });
    }

    /**
     * Returns the kapycoins of the authenticated user
     */
    getUserConversations = (): Promise<any[]> => {
        return new Promise(async (resolve, reject) => {
            try {
                const userAuth: any = await this.getUserInfo();
                const conversations = await this.database.listDocuments("kapygpt", "conversation", [`contains(userIds, ${userAuth.$id})`]);
                console.log("conversations", conversations)
                // create conversation for all ai assistents if not exist for the user (this may change in the future to be more dynamic)
                let ialist = await this.getAIAssistents();
                for (let ia of ialist) {
                    if (!conversations.documents.find(conversation => conversation.selectedIa == ia?.id)) {
                        this.database.createDocument(
                            'kapygpt',
                            'conversation',
                            ID.unique(),
                            {
                                userIds: [userAuth.$id],
                                selectedIa: ia?.id || "gpt35",
                                isGroup: false,
                                title: "KapyAI"
                            }
                        ).then(response => console.log("conversation created")).catch(error => console.log("error", error));
                    }
                }
                let userConversations = conversations.documents;
                userConversations = userConversations.map(conversation => ({...conversation, iaInfo: ialist.find(ia => ia.id == conversation.selectedIa)}));
                resolve(userConversations);
            } catch (error) {
                reject(this._handleError(error));
            }
        });
    }

    getAiOfConversation = (conversationId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const conversation = await this.database.getDocument("kapygpt", "conversation", conversationId);
                let ialist = await this.getAIAssistents();
                let iaInfo = ialist.find(ia => ia.id == conversation.selectedIa);
                resolve(iaInfo);
            } catch (error) {
                reject(this._handleError(error));
            }
        });
    }

    /**
 * Returns the kapycoins of the authenticated user
 */
    getUserConversationMessages = (id): Promise<any[]> => {
        return new Promise(async (resolve, reject) => {
            try {
                const conversations = await this.database.listDocuments("kapygpt", "message", [Query.equal("conversationId", id), Query.orderDesc("time")]);
                resolve(conversations.documents.reverse());
            } catch (error) {
                reject(this._handleError(error));
            }
        });
    }

    addMessage = (data) => {
        return new Promise((resolve, reject) => {
            this.database.createDocument("kapygpt", "message", ID.unique(), convertDataToAppWriteMsg(data))
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(this._handleError(error)));
        })
    }

    receiveAiMessage = async (data) => {
        let aiauthor = data.meta.receiver || "gpt35";
        const userData = await this.getUserData();
        console.log("userData", userData)
        const conversations = await this.database.listDocuments("kapygpt", "message", [
            Query.equal("authorId", data.authorId), 
            Query.greaterThan("time", new Date().toISOString().split("T")[0] + "T00:00:00.000Z"), 
            Query.limit(1)])
        console.log("conversations", conversations)

        const userAccess =  this.checkUserAccess(userData, conversations, aiauthor);
        console.log("userAccess", userAccess)

        if (!userAccess.continue) {
            return new Promise((resolve, reject) => {
                this.database.createDocument("kapygpt", "message", data.airesponseId, convertDataToAiWriteMsg({ ...data, text: messagesLimitText, aiauthor: aiauthor, kapycoins: 0}))
                    .then(async (response) => resolve(response))
                    .catch(error => reject(this._handleError(error)));
            })
        }

        return new Promise(async (resolve, reject) => {
            const result =  this.functions.createExecution(
                'chatresponse', // functionId
                JSON.stringify({ ...data, time: new Date().toISOString() }), // body (optional)
                false, // async (optional)
                '/', // path (optional)
                'POST', // method (optional)
                {} // headers (optional)
            );

            result.then(async (res) => {
                const response = JSON.parse(res?.responseBody)

                // caculate Kapycoins 
                let kapycoins = await this.calculateKapycoins(aiauthor, response.content);
                let newKapycoins = userAccess.userType == "free" ? userData.kapycoins : userData.kapycoins - kapycoins;

                this.database.updateDocument("kapygpt", "message", data.airesponseId, convertDataToAiWriteMsg({ ...data, text: response.content, aiauthor: aiauthor, kapycoins }))
                .then((response) => {
                    console.log("response kapycoins", kapycoins)
                    this.database.updateDocument("kapygpt", "user", userData.$id, { kapycoins: newKapycoins })
                    resolve(response)
                })
                .catch(error => reject(this._handleError(error)));
            })
            .catch(error => {
                console.log("sending error", error)
                this.database.createDocument("kapygpt", "message", data.airesponseId, convertDataToAiWriteMsg({ ...data, text: askAgainTextMarkdown, aiauthor: aiauthor, kapycoins: 0}))
                .then((response) => resolve(response))
                .catch(error => reject(this._handleError(error)));
            });

            // axios.post(process.env.REACT_APP_API_BACKEND_URL, { ...data, time: new Date().toISOString() })
            // .then((response: any) => {})

        })
    }

    checkUserAccess = (userData, conversations, aiauthor) => {
        console.log("HELPER: aiauthor", aiauthor)
        console.log("HELPER: Conversations", conversations)

        if (conversations.total <= 8 && (aiauthor == "gpt35")) {
            return { continue: true, userType: "free" }
        } 
        else if (conversations.total <= 6 && (aiauthor == "xtrem")) {
            return { continue: true, userType: "free" }
        } 
        else if (conversations.total <= 5 && (["image fast", "image hd", "gpt4"].indexOf(aiauthor) > -1) ) {
            return { continue: true, userType: "free" }
        } 
        else if (conversations.total <= 4) {
            return { continue: true, userType: "free" }
        } 
        else if (userData.kapycoins && userData.kapycoins > 1) {
            return { continue: true, userType: "pro" }
        }
        else {
            return { continue: false, userType: "free" }
        }
    };

    calculateKapycoins = async (aiauthor, text_content) => {
        let ialist = await this.getAIAssistents(); 
        console.log("ialist", ialist)
        let iaInfo = ialist.find(ia => ia.id == aiauthor);

        if(iaInfo.isFile) {
            return iaInfo.power || 10;
        }

        // caculate Kapycoins 60 words = 1 unit
        let unit = Math.floor((text_content || "").split(" ").length / 60) + 1;
        let kapycoins = unit * (iaInfo.power || 2);
        return kapycoins;
    }

    addPaymentToUser = async (Package, paymentType, status) => {
        console.log("HELPER: Add Package to DB", Package)
        const userData = await this.getUserData();

            this.database.createDocument("kapygpt", "payment", ID.unique(), { 
                userId: userData.$id,
                paymentType: paymentType?.id || "cinetpay",
                kapycoins: Package.kapycoins,
                createdDtm: new Date().toISOString(),
                status,
                package: JSON.stringify(Package)
            })
            .then(response => console.log("payment created")).catch(error => console.log("error", error));

            let valueToAdd = status == "success" ? Package.kapycoins : 0;

            this.database.updateDocument("kapygpt", "user", userData.$id, { kapycoins: userData.kapycoins + valueToAdd})
            .then(response => console.log("kapycoins updated")).catch(error => console.log("error", error));
    }

    initUserData = async (user) => {
        this.database.createDocument(
            'kapygpt',
            'user',
            user.$id,
            {
                "uid": user?.$id,
                "email": user?.email,
                "displayName": user?.name,
                "phoneNumber": user?.phoneNumber,
                "photoUrl": user?.photoUrl,
                "kapycoins": 0,
                "lastLoginTime": new Date().toISOString(),
                "createdDtm" : new Date().toISOString(),
            }
        ).then(response => console.log("user created")).catch(error => console.log("error", error));

        let ialist = await this.getAIAssistents();

        for (let ia of ialist) {
            this.database.createDocument(
                'kapygpt',
                'conversation',
                ID.unique(),
                {
                    userIds: [user?.$id],
                    selectedIa: ia?.id || "gpt35",
                    isGroup: false,
                    title: "KapyAI"
                }
            ).then(response => console.log("conversation created")).catch(error => console.log("error", error));
        }


        getLocationInfo().then(location => {
            this.database.updateDocument(
                'kapygpt',
                'location',
                user?.$id,
                {
                    "loc": location?.loc,
                    "country": location?.country,
                    "city": location?.city,
                    "org": location?.org,
                    "timezone": location?.timezone,
                    "ip": location?.ip,
                    "region" : location?.region,
                }
            ).then(response => console.log("conversation created")).catch(error => console.log("error", error));
        })

        this.locale.get().then(response => {
            this.database.updateDocument(
                'kapygpt',
                'location',
                user?.$id,
                {
                    "appwriteip": response?.ip,
                    "eu": response?.eu,
                    "continent": response?.continent,
                    "countryName": response?.country,
                    "countryCode": response?.countryCode,
                    "continentCode": response?.continentCode,
                    "currency": response?.currency,
                }
            ).then(response => console.log("conversation created")).catch(error => console.log("error", error));
        })
        
        

    }

    updateUserData = (session) => {
        this.database.updateDocument(
            'kapygpt',
            'location',
            session?.userId,
            {
                "os": JSON.stringify({"osCode": session?.osCode, "osName": session?.osName, "osVersion": session?.osVersion}),
                "client": JSON.stringify({"clientCode": session?.clientCode, "clientName": session?.clientName, "clientVersion": session?.clientVersion , "clientEngine" : session?.clientEngine, "clientEngineVersion" : session?.clientEngineVersion}),
                "device": JSON.stringify({ "deviceName": session?.deviceName, "deviceBrand": session?.deviceBrand, "deviceModel": session?.deviceModel}),
                "countryCode" : session?.countryCode,
                "countryName" : session?.countryName,

            }
        ).then(response => console.log("conversation created")).catch(error => console.log("error", error));

        getLocationInfo().then(location => {
            this.database.updateDocument(
                'kapygpt',
                'location',
                session?.userId,
                {
                    "loc": location?.loc,
                    "country": location?.country,
                    "city": location?.city,
                    "org": location?.org,
                    "timezone": location?.timezone,
                    "ip": location?.ip,
                    "region" : location?.region,
                }
            ).then(response => console.log("conversation created")).catch(error => console.log("error", error));
        })

        this.locale.get().then(response => {
            this.database.updateDocument(
                'kapygpt',
                'location',
                session?.userId,
                {
                    "appwriteip": response?.ip,
                    "eu": response?.eu,
                    "continent": response?.continent,
                    "countryName": response?.country,
                    "countryCode": response?.countryCode,
                    "continentCode": response?.continentCode,
                    "currency": response?.currency,
                }
            ).then(response => console.log("conversation created")).catch(error => console.log("error", error));
        })
    }

    getAIAssistents = async () => {
        if (this.aiassistents?.length && this.aiassistents?.length > 0) {
            return this.aiassistents;
        }
        this.aiassistents = (await this.database.listDocuments("kapygpt", "assistantai")).documents;
        return this.aiassistents;
    }
}


let _appWriteBackend: AppWriteBackend = null;

const setLoggeedInUser = user => {
    localStorage.setItem("authUser", JSON.stringify(user));
};

/**
 * Initilize the backend
 * @param {*} config
 */
const initAppWriteBackend = config => {
    if (!_appWriteBackend) {
        _appWriteBackend = new AppWriteBackend();
    }
    return _appWriteBackend;
};

/**
 * Returns the firebase backend
 */
const getAppWriteBackend = () => {
    return _appWriteBackend;
};

export { initAppWriteBackend, getAppWriteBackend, setLoggeedInUser };