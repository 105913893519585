import React, { useEffect, useState } from "react";

// interface
import { BasicDetailsTypes } from "../../../data/myProfile";
import { Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
// import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { getAppWriteBackend } from "../../../helpers/appwrite/appwrite_helper";

interface UserDescriptionProps {
  basicDetails: BasicDetailsTypes;
}
const UserDescription = ({ basicDetails }: UserDescriptionProps) => {
  let dateCreated = basicDetails.createdAt ? (new Date(Number(basicDetails.createdAt))).toDateString() : "-";
  const appwriteBackend = getAppWriteBackend();
  // const firebaseBackend = getFirebaseBackend();
  const [kapyCoins, setKapyCoins] = useState(0)
  useEffect(() => {
    appwriteBackend.getUserData().then((user) => {
      setKapyCoins(user?.kapycoins)
    })
  }, [appwriteBackend])
  

  return (
    <>
      {/* <div className="text-muted">
        <p className="mb-4">
          {basicDetails && basicDetails.description
            ? basicDetails.description
            : "-"}
        </p>
      </div> */}

      <div>
        <div className="d-flex py-2">
          <div className="flex-shrink-0 me-3">
            <i className="bx bx-user align-middle text-muted"></i>
          </div>
          <div className="flex-grow-1">
            <p className="mb-0">
              {basicDetails.displayName || basicDetails.name
                ? basicDetails.displayName || basicDetails.name
                : "KapyAI User"}
            </p>
          </div>
        </div>

        <div className="d-flex py-2">
          <div className="flex-shrink-0 me-3">
            <i className="bx bx-message-rounded-dots align-middle text-muted"></i>
          </div>
          <div className="flex-grow-1">
            <p className="mb-0">
              {basicDetails && basicDetails.email ? basicDetails.email : "-"}
            </p>
          </div>
        </div>

        <div className="d-flex py-2">
          <div className="flex-shrink-0 me-3">
            <i className="bx bx-time align-middle text-muted"></i>
          </div>
          <div className="flex-grow-1">
            <p className="mb-0">
              Member since {basicDetails.$createdAt || dateCreated}
            </p>
          </div>
        </div>

        <div className="d-flex py-2">
          <div className="flex-shrink-0 me-3">
            <i className="bx bx-time align-middle text-muted"></i>
          </div>
          <div className="flex-grow-1">
            <p className="mb-0">
              KapyCoins: {kapyCoins ? kapyCoins : 0}
            </p>
          </div>
        </div>

        {/* <div className="d-flex py-2">
          <div className="flex-grow-1">
            
              <div className="mt-4">
                <Link
                  to="/delete-account"
                  className="btn btn-primary w-100 waves-effect waves-light"
                >
                  Delete Account
                </Link>
              </div>
           
          </div>
        </div> */}
      </div>
    </>
  );
};

export default UserDescription;
