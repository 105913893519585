import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { ProfileActionTypes } from "./types";
import { profileApiResponseSuccess, profileApiResponseError } from "./actions";

import { getProfileDetails as getProfileDetailsApi } from "../../api/index";
// import { getFirebaseBackend } from "../../helpers/firebase_helper";
import { getAppWriteBackend } from "../../helpers/appwrite/appwrite_helper";

// initialise appwrite
const appWriteBackend = getAppWriteBackend();

function* getProfileDetails() {
  try {
    let response: Promise<any>;
    if (process.env.REACT_APP_DEFAULTAUTH === "appwrite") {
        response = yield call(
          appWriteBackend.getUserInfo
      );
    }
    // else if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   let fireBaseBackend = getFirebaseBackend();
    //     response = yield call(
    //     fireBaseBackend.getAuthenticatedUser
    //   );
    // } 
    else {
      response = yield call(getProfileDetailsApi);
    }
    
    yield put(
      profileApiResponseSuccess(
        ProfileActionTypes.GET_PROFILE_DETAILS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      profileApiResponseError(ProfileActionTypes.GET_PROFILE_DETAILS, error)
    );
  }
}

export function* watchGetProfileDetails() {
  yield takeEvery(ProfileActionTypes.GET_PROFILE_DETAILS, getProfileDetails);
}

function* profileSaga() {
  yield all([fork(watchGetProfileDetails)]);
}

export default profileSaga;
