const WhiteLogo = () => {
    return (
        // <svg
        //   xmlns="http://www.w3.org/2000/svg"
        //   width="30"
        //   height="30"
        //   viewBox="0 0 24 24"
        // >
        //   <path d="M8.5,18l3.5,4l3.5-4H19c1.103,0,2-0.897,2-2V4c0-1.103-0.897-2-2-2H5C3.897,2,3,2.897,3,4v12c0,1.103,0.897,2,2,2H8.5z M7,7h10v2H7V7z M7,11h7v2H7V11z" />
        // </svg>
        <svg style={{ "verticalAlign": "sub" }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width={30} height={30} viewBox="0 0 117.72 135.67">
            <path className="cls-1" style={{ "fill": "white" }} d="m51.07,94.19c-.28-.67-.6-1.33-.98-1.94-.88-1.46-2.07-2.73-3.5-3.67-1.81-1.17-3.94-1.74-6.08-1.81-1.98-.07-3.97.29-5.7,1.26-2.48,1.39-5.16,4.48-5.29,7.55-.02.34,0,.68.05,1.01.24,1.59,1.46,2.73,2.94,3.23,1.3.43,2.58.06,3.9-.13.98-.14,1.97-.19,2.96-.18,2.8,0,5.67.16,8.42.72.89.18,1.87.18,2.66-.3,2.09-1.26,1.35-3.91.61-5.73Z" />
            <path className="cls-1" style={{ "fill": "white" }} d="m81.58,87.52c-1.74-.97-3.73-1.33-5.7-1.26-2.14.08-4.27.64-6.08,1.81-1.44.93-2.62,2.2-3.5,3.66-.38.62-.71,1.27-.98,1.95-.74,1.81-1.48,4.47.61,5.73.8.48,1.78.48,2.67.3,2.75-.57,5.61-.72,8.42-.72.99,0,1.98.05,2.96.19,1.31.19,2.6.56,3.89.13,1.48-.49,2.7-1.64,2.94-3.23.05-.34.07-.67.05-1.01-.13-3.07-2.8-6.16-5.29-7.55Z" />
            <path className="cls-1" style={{ "fill": "white" }} d="m107.79,67.52c-2.5-9.96-6.3-19.24-10.22-25.38-1.04-1.63-2.33-3.07-3.77-4.34-1.48-1.31-3.13-2.43-4.84-3.39-1.76-.99-3.6-1.83-5.5-2.55-1.89-.72-3.81-1.33-5.76-1.84-1.86-.49-3.73-.9-5.63-1.23-3.25-.58-6.54-.95-9.84-1.1l-1.28-7.82c3.16-1.14,5.42-4.15,5.42-7.7,0-4.52-3.66-8.19-8.19-8.19s-8.19,3.67-8.19,8.19c0,3.3,1.95,6.13,4.76,7.43l-1.02,8.17c-1.66.11-3.33.27-4.98.5-1.67.23-3.32.52-4.97.85-2.55.52-5.07,1.19-7.55,2.02-1.77.59-3.51,1.28-5.2,2.08-1.67.78-3.27,1.7-4.78,2.75-1.48,1.03-2.85,2.19-4.06,3.51-.75.81-1.49,1.7-2.05,2.66-3.93,6.15-7.73,15.45-10.24,25.42-1.02.99-20.36,20.08-2.03,32.25.49,2.36,1.18,4.55,2.1,6.53.59,1.28,1.27,2.52,2.02,3.71.74,1.2,1.56,2.35,2.44,3.45.89,1.11,1.84,2.16,2.84,3.16,1.01,1.01,2.08,1.97,3.19,2.87,1.12.92,2.29,1.78,3.49,2.58,1.22.82,2.48,1.58,3.77,2.28,1.31.71,2.65,1.37,4.01,1.96,1.37.6,2.77,1.16,4.19,1.65,1.43.49,2.88.94,4.34,1.32,13.74,3.61,29.67,3.2,43.01-1.86,1.43-.54,2.84-1.14,4.22-1.8,1.37-.65,2.71-1.36,4.01-2.13,1.29-.76,2.55-1.58,3.76-2.46,1.19-.87,2.35-1.79,3.45-2.78,1.09-.97,2.12-1.99,3.1-3.07.97-1.07,1.88-2.2,2.72-3.38.84-1.17,1.6-2.4,2.3-3.67.33-.61.64-1.22.92-1.84.92-1.97,1.61-4.15,2.1-6.5,18.67-12.37-1.6-31.88-2.05-32.31Zm-49.96,59c-22.38,0-40.52-14.99-40.52-33.48s18.14-33.48,40.52-33.48,40.52,14.99,40.52,33.48-18.14,33.48-40.52,33.48Z" />
            <path className="cls-1" style={{ "fill": "white" }} d="m64.83,113.29h-12.6c-.96,0-1.49,1.11-.9,1.86,5.68,7.17,11.54,2.93,14.31.09.71-.73.2-1.95-.82-1.95Z" />
        </svg>

    );
};

export default WhiteLogo;