import React from 'react'
import ReactDom from 'react-dom'
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import Highlighter from './Highlighter'




export default function Markdown({ value }) {
  return (
    <ReactMarkdown
    className='ctext-content'
    children={value}
    components={{
      code({node, inline, className, children, ...props}) {
        return <Highlighter node={node} inline={inline} className={className} children={children} {...props} />
      }
    }}
  />
  );
}

