import React, { useEffect, useState } from "react";
import { Alert, Row, Col, Form, Button, UncontrolledTooltip } from "reactstrap";
import { Input, Label, FormFeedback } from "reactstrap";

// hooks
import { useRedux } from "../../hooks/index";

// router
import { Link, Redirect, useHistory } from "react-router-dom";

// validations
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

// hooks
import { useProfile } from "../../hooks";

//actions
import { registerUser } from "../../redux/actions";

// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// import { getFirebaseBackend } from "../../helpers/firebase_helper";
import { getLocationInfo } from "../../helpers/utils";
import { DefaultIonLifeCycleContext } from "@ionic/react";
import { getAppWriteBackend } from "../../helpers/appwrite/appwrite_helper";
import { set } from "date-fns";

interface RegisterProps { }
const Register = (props: RegisterProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const newAppWriteBackend = getAppWriteBackend();
  const [rError, setRError] = useState(null);

  const [otp, setotp] = useState('');
  const [final, setfinal] = useState(null);
  const [locationInfo, setLocationInfo] = useState(null);
  const [registerStep, setRegisterStep] = useState(1);
  const [phoneError, setPhoneError] = useState(null);
  const [password, setPassword] = useState("");
  const history: any = useHistory();

  // Sent OTP
  const signin = () => {

    if (phone === "" || phone.length < 9){ setPhoneError("Invalid Phone Number"); return;} 

    newAppWriteBackend.updatePhoneNumber(phone, password)
      .then((result) => {
        console.log("Sign In Phone" , result);
        setfinal(result);
        setRegisterStep(3);
        setPhoneError(null);
      })
      .catch((err) => setPhoneError(err));

      // newFirebaseBackend.signInWithPhoneNumber(phone)
    //   .then((result) => {
    //     setfinal(result);
    //     setRegisterStep(2);
    //     setPhoneError(null);
    //   })
    //   .catch((err) => {
    //     if(newFirebaseBackend.recaptchaVerifier)newFirebaseBackend.recaptchaVerifier.clear();
    //     setPhoneError(err);
    //     //window.location.reload()
    //   });
  }

  // Validate OTP
  const ValidateOtp = () => {
    if (otp === null || final === null)
      return;

    newAppWriteBackend.verificationPhoneNumberConfirmed(final.userId, otp)
    .then((result) => {
      console.log("Phone Confirm" , result); 
      dispatch(registerUser(result));
      setPhoneError(null);
    }).catch((err) => setPhoneError("Wrong Code"))
    

    // (final as any).confirm(otp).then((result) => {
    //   // success
    //   setRegisterStep(3);
    //   setPhoneError(null);
    //   newFirebaseBackend.addNewUserToFirestore(result.user, locationInfo);
    // }).catch((err) => {
    //   console.log(err);
    //   // error
    //   setPhoneError("Wrong Code");
    // })
  }

  //change number
  const changeNumber = () => {
    setRegisterStep(2);
    setPhoneError(null);
  }


  // call useEffect to get country code
  useEffect(() => {
    getLocationInfo()
    .then((res) => {
      setLocationInfo(res);
    })
    ;
  }, []);


  const { user, registrationError, regLoading } = useAppSelector(state => ({
    user: state.Register.user,
    registrationError: state.Register.registrationError,
    regLoading: state.Register.loading,
    isUserRegistered: state.Register.isUserRegistered,
  }));

  const resolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .email("This value should be a valid email.")
        .matches(/^[a-zA-Z0-9_.+-]+@(gmail|yahoo|outlook)\.[a-z]{2,}$/, "We only accept gmail, yahoo and outlook emails")
        .required("Please Enter E-mail."),
        displayName: yup
        .string()
        .required("Please Enter username.")
        .min(3, "Username must be at least 3 characters")
      ,
      password: yup
        .string()
        .required("Please Enter Password.")
        .min(8, "Password must be at least 8 characters")
    })
  );

  const defaultValues: any = {};

  const methods = useForm({ defaultValues, resolver });
  const [phone, setPhone] = useState("")
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const onSubmitForm = (values) => {
    setPassword(values.password);
    newAppWriteBackend.registerUser(values.email, values.password, values.displayName)
    .then((result) => {
      console.log("Register User", result);
      setRegisterStep(2);
      //dispatch(registerUser(result));
    })
    .catch((err) => setRError(err));
    //dispatch(registerUser(values));
  };

  const { userProfile, loading } = useProfile();

    // useeffect return to dashboard if user is already logged in
    useEffect(() => {
      if(user) {
        history.push("/dashboard");
      }
    }, [user]);

  if (userProfile && !loading) {
    return <Redirect to={{ pathname: "/dashboard" }} />;
  }

  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4">
            <AuthHeader
              title="Register Account"
              subtitle="Get your free KapyGPT account now."
            />

            {user && user ? (
              <Alert color="success">Register User Successfully</Alert>
            ) : null}

            {registrationError || rError ? (
              <Alert color="danger">{registrationError || rError}</Alert>
            ) : null}

            {phoneError ? (
              <Alert color="danger">{phoneError}</Alert>
            ) : null}

            {registerStep == 1 && <Form
              onSubmit={handleSubmit(onSubmitForm)}
              className="position-relative"
            >
              {regLoading && <Loader />}
              <div className="mb-3">
                <FormInput
                  label="Email"
                  type="email"
                  name="email"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter Email"
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <FormInput
                  label="Username"
                  type="text"
                  name="displayName"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter username"
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <FormInput
                  label="Password"
                  type="password"
                  name="password"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  className="form-control pe-5"
                  placeholder="Enter Password"
                />
              </div>
              <div className="mb-4">
                <p className="mb-0">
                  By registering you agree to the KapyAI{" "}
                  <Link to="#" className="text-primary">
                    Terms of Use
                  </Link>
                </p>
              </div>

              <div className="text-center">
                <Button
                  color="primary"
                  className="w-100  waves-effect waves-light"
                  type="submit"
                >
                  Finish Registration
                </Button>
              </div>
              {/* <div className="mt-4 text-center">
                <div className="signin-other-title">
                  <h5 className="font-size-14 mb-4 title">Sign up using</h5>
                </div>
                <Row className="">
                  <div className="col-4">
                    <div>
                      <button
                        type="button"
                        className="btn btn-light w-100"
                        id="facebook"
                      >
                        <i className="mdi mdi-facebook text-indigo"></i>
                      </button>
                    </div>
                    <UncontrolledTooltip placement="top" target="facebook">
                      Facebook
                    </UncontrolledTooltip>
                  </div>
                  <div className="col-4">
                    <div>
                      <button
                        type="button"
                        className="btn btn-light w-100"
                        id="twitter"
                      >
                        <i className="mdi mdi-twitter text-info"></i>
                      </button>
                    </div>
                    <UncontrolledTooltip placement="top" target="twitter">
                      Twitter
                    </UncontrolledTooltip>
                  </div>
                  <div className="col-4">
                    <div>
                      <button
                        type="button"
                        className="btn btn-light w-100"
                        id="google"
                      >
                        <i className="mdi mdi-google text-danger"></i>
                      </button>
                    </div>
                    <UncontrolledTooltip placement="top" target="google">
                      Google
                    </UncontrolledTooltip>
                  </div>
                </Row>
              </div> */}
            </Form>}

            {registerStep == 2 && <div>
              <div className="mb-3">
                <Label htmlFor={"phone"} className="form-label">
                  Phone Number
                </Label>
                <PhoneInput
                  inputProps={{
                    name: 'phone',
                    required: true,
                    autoFocus: true,
                    //...register("phone")
                  }}
                  country={locationInfo && locationInfo?.country ? locationInfo.country.toLowerCase() : "us"}
                  inputClass="form-control pe-5 w-100"
                  value={phone}
                  enableSearch
                  enableLongNumbers
                  countryCodeEditable={false}
                  onChange={ephone => { setPhone(ephone) }}
                />
              </div>
              <div id="recaptcha-container"></div>
              <div className="text-center">
                <Button
                  color="primary"
                  className="w-100 waves-effect waves-light"
                  //type="submit"
                  type="button"
                  onClick={signin}
                >
                  Verify Phone Number
                </Button>
              </div>
            </div>}

            {registerStep == 3 && <div>
              <div className="mb-3">
                <FormInput
                  label="Verification Code"
                  type="text"
                  name="verificationCode"
                  smalllabel="(Enter the verification code sent to your phone number)"
                  //register={register}
                  value={otp}
                  onChange={(e) => setotp(e.target.value)}
                  errors={errors}
                  //control={control}
                  labelClassName="form-label"
                  placeholder="Enter Verification Code"
                  className="form-control"
                />
              </div>
              <div id="recaptcha-container"></div>
              <div className="text-center">
                <Button
                  color="primary"
                  className="w-50 waves-effect waves-light"
                  //type="submit"
                  type="button"
                  onClick={ValidateOtp}
                >
                  Verify Code
                </Button>
                <Button
                  color="primary"
                  className="w-50 waves-effect waves-light"
                  //type="submit"
                  type="button"
                  onClick={changeNumber}
                >
                  Go Back
                </Button>
              </div>
            </div>}

            

            <div className="mt-3 text-center text-muted">
              <p>
                Already have an account ?{" "}
                <Link
                  to="/auth-login"
                  className="fw-medium text-decoration-underline"
                >
                  Login
                </Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default Register;
