import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { configureStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";

/////////////////////////////////////////

import { PushNotifications } from '@capacitor/push-notifications';
import { isPlatform, getPlatforms } from '@ionic/react';
import {LocalNotifications} from '@capacitor/local-notifications';
import { Share } from '@capacitor/share';
import { Browser } from '@capacitor/browser';


const addListeners = async () => {
  console.log("listener adding")
  await PushNotifications.addListener('registration', token => {
    console.info('Registration token: ', token.value);
  });

  await PushNotifications.addListener('registrationError', err => {
    console.error('Registration error: ', err.error);
  });

  await PushNotifications.addListener('pushNotificationReceived', notification => {
    console.log('Push notification received: ', notification);
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    console.log('Push notification action performed', notification.actionId, notification.inputValue);
  });
}

const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();
  console.log(permStatus)
  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }

  await PushNotifications.register();
}

const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log('delivered notifications', notificationList);
}

console.log(getPlatforms())
if (isPlatform("mobile") || isPlatform("android") || isPlatform("ios")) {
  registerNotifications()
  .then()
  .catch(err => {
    console.error(err)
  })
  console.log("Connect to listener")
  addListeners()
  .then()
  .catch(err => {
    console.error(err)
  })

  LocalNotifications.requestPermissions()
  .then()
  .catch(err => {
    console.error(err)
  })
  // LocalNotifications.schedule({
  //   notifications: [
  //     {
  //       title: "Title Kapytest",
  //       body: "Body Kapytest",
  //       id: 1,
  //       schedule: { at: new Date(Date.now() + 1000 * 5) },
  //       sound: null,
  //       attachments: null,
  //       actionTypeId: "",
  //       extra: null
  //     }
  //   ]
  // }).then();

  // setTimeout(async () => {
    
  //   try {
  //     // await Share.share({
  //     //   title: 'Share KapyGPT Answer',
  //     //   text: 'Here is your answer from KapyGPT AI: "This is a test answer from KapyGPT AI" Try KapyGPT AI now!',
  //     //   url: 'https://kapygpt.kapygenius.com',
  //     //   dialogTitle: 'Share with buddies',
  //     // })

  //     await Browser.open({ url: 'http://capacitorjs.com/' });
      
  //   } catch (error) {
  //     console.log(error)
  //   }

  // }, 8000)

}




///////////////////////////

ReactDOM.render(
  <Provider store={configureStore({})}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
