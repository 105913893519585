import React, { useEffect, useState } from "react";
import { uid } from "uid";

// hooks
import { useRedux } from "../../../hooks/index";

// actions
import {
  toggleUserDetailsTab,
  getChatUserConversations,
  onSendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  deleteUserMessages,
  toggleArchiveContact,
} from "../../../redux/actions";

// hooks
import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";

// interface
import { MessagesTypes } from "../../../data/messages";

// dummy data
import { pinnedTabs } from "../../../data/index";
import { getAppWriteBackend } from "../../../helpers/appwrite/appwrite_helper";

interface IndexProps {
  isChannel: boolean;
}
const Index = ({ isChannel }: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    chatUserDetails,
    chatUserConversations,
    isUserMessageSent,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
    selectedChat,
    isReceivedMessage,
    dataToReadStreamingMessage
  } = useAppSelector(state => ({
    chatUserDetails: state.Chats.chatUserDetails,
    chatUserConversations: state.Chats.chatUserConversations,
    isUserMessageSent: state.Chats.isUserMessageSent,
    isMessageDeleted: state.Chats.isMessageDeleted,
    isMessageForwarded: state.Chats.isMessageForwarded,
    isUserMessagesDeleted: state.Chats.isUserMessagesDeleted,
    isImageDeleted: state.Chats.isImageDeleted,
    selectedChat: state.Chats.selectedChat,
    isReceivedMessage: state.Chats.isReceivedMessage,
    dataToReadStreamingMessage: state.Chats.dataToReadStreamingMessage
  }));

  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };

  /*
  hooks
  */
  const { userProfile } = useProfile();

  /*
  reply handeling
  */
  const [replyData, setReplyData] = useState<
    null | MessagesTypes | undefined
  >();
  const onSetReplyData = (reply: null | MessagesTypes | undefined) => {
    setReplyData(reply);
  };
  const [temporalAiMessage, setTemporalAiMessage] = useState(null)


  /*
  send message
  */
  const onSend = (data: any) => {
    let params: any = {
      conversationId: selectedChat,
      text: data.text && data.text,
      time: (new Date()).toISOString(),
      isAuthorAi: false,
      aiauthor: null,
      authorId:  userProfile.$id,
      airesponseId: uid(20),
      image: data.image && data.image,
      attachments: data.attachments && data.attachments,
      meta: {
        receiver: chatUserDetails.id,
        sender: userProfile.uid,
      },
    };
    if (replyData && replyData !== null) {
      params["replyOf"] = replyData;
    }

    dispatch(onSendMessage(params));
    //if (!isChannel) {
      // setTimeout(() => {
      //   dispatch(receiveMessage(chatUserDetails.id));
      // }, 1000);
      // setTimeout(() => {
      //   dispatch(readMessage(chatUserDetails.id));
      // }, 1500);
      // setTimeout(() => {
      //   dispatch(receiveMessageFromUser(chatUserDetails.id));
      // }, 1000);
    //}
    //setReplyData(null);
  };

  useEffect(() => {
    if (
      isUserMessageSent ||
      isMessageDeleted ||
      isMessageForwarded ||
      isUserMessagesDeleted ||
      isImageDeleted ||
      isReceivedMessage == "success"
    ) {
      dispatch(getChatUserConversations(selectedChat));
    }
  }, [
    dispatch,
    isUserMessageSent,
    chatUserDetails,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
    isReceivedMessage
  ]);

  useEffect(() => {
    let unsub = () => {};
    const appwritebackend = getAppWriteBackend();
    if (isReceivedMessage == "waiting" && dataToReadStreamingMessage) {
      unsub = appwritebackend.subscribe(`databases.kapygpt.collections.message.documents.${dataToReadStreamingMessage.airesponseId}`, (response) => {
        console.log("response", response);
        setTemporalAiMessage(response);
      });
    }
    return () => {
      unsub();
      setTemporalAiMessage(null);
    }
  }, [isReceivedMessage]);

  const onDeleteMessage = (messageId: string | number) => {
    dispatch(deleteMessage(chatUserDetails.id, messageId));
  };

  const onDeleteUserMessages = () => {
    dispatch(deleteUserMessages(chatUserDetails.id));
  };

  const onToggleArchive = () => {
    dispatch(toggleArchiveContact(chatUserDetails.id));
  };

  return (
    <>
      <UserHead
        chatUserDetails={chatUserDetails}
        pinnedTabs={pinnedTabs}
        onOpenUserDetails={onOpenUserDetails}
        onDelete={onDeleteUserMessages}
        isChannel={isChannel}
        onToggleArchive={onToggleArchive}
      />
      <Conversation
        chatUserConversations={chatUserConversations}
        chatUserDetails={chatUserDetails}
        onDelete={onDeleteMessage}
        onSetReplyData={onSetReplyData}
        isChannel={isChannel}
        isReceivedMessage={isReceivedMessage}
        temporalAiMessage={temporalAiMessage}
      />
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      />
    </>
  );
};

export default Index;
