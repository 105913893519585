import React from "react";

// interface
import { UserTypes } from "../../../data/chat";

// component
import ChatAi from "./ChatAI";
interface ChatAIMessagesProps {
  conversations: Array<any>;
  selectedChat: string | number;
  onSelectChat: (id: number | string) => void;
}
const ChatAiMessages = ({ conversations , selectedChat, onSelectChat }: ChatAIMessagesProps) => {
  return (
    <>
      <h5 className="mb-3 px-4 mt-1 font-size-11 text-muted text-uppercase">
        KapyAI Assistants List
      </h5>

      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list">
          {(conversations || []).map((conversation: any, key: number) => (
            <ChatAi
              user={conversation}
              key={key}
              selectedChat={selectedChat}
              onSelectChat={onSelectChat}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default ChatAiMessages;
