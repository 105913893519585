import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';


export default function Highlighter({ node, inline, className, children, ...props }) {
    const match = /language-(\w+)/.exec(className || '');
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setCopied(false)
        }, 1500)
        return () => clearTimeout(timer)
    }, [copied])

    return (!inline && match ? (
            <div className='card bg-success highlighter'>
                <div className="bg-success card-header d-flex justify-content-between align-items-center">
                    <span style={{ color: "white" }}>KapyGPT - {match[1]}</span>
                    <CopyToClipboard text={children} onCopy={() => setCopied(true)}>
                        {copied ? <span style={{ color: "white" }}>Copied</span> : <span style={{cursor:"pointer"}}  className= "text-light">Copy <i className="bx bx-copy text-muted ms-2"></i></span>}
                    </CopyToClipboard>
                </div>
                <div className="card-content">
                    <SyntaxHighlighter
                        children={String(children).replace(/\n$/, '')}
                        style={materialDark}
                        language={match[1]}
                        wrapLines
                        wrapLongLines
                        PreTag="div"
                        {...props}
                    />
                </div>
            </div>
    ) : (
        <code className={className} {...props}>
            {children}
        </code>
    ))
}
