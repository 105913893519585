import uniqid from 'uniqid';

export const writingAiText = "Writing...";
export const sendingLimitText = "You are sending too many messages. Please wait until tomorrow. if you want unlimited chat please contact: <a href='mailto:contact@kapygenius.com'>contact@kapygenius.com</a>. <br/>Thank you 😊.";
export const messagesLimitText = "You have reached your daily limit. Please wait until tomorrow. you  can get more messages by adding more KapyCoins 👇.";
export const messageOnlyForPro = "This chat is only for Pro users with KapyCoins. Please upgrade to Pro by adding more KapyCoins 👇.";
export const sendingLimit = 50;
export const messagesLimit = 110;
export const kapyCoinsLimit = 50;
export const askAgainText = "Something went wrong. Please ask again. If the problem persist please contact: <a href='mailto:contact@kapygenius.com'>contact@kapygenius.com</a>, <br/> Thank you 😊.";
export const askAgainTextMarkdown = "Something went wrong. Please ask again. If the problem persist please contact: <contact@kapygenius.com>. \nThank you 😊.";
export const proChatIds = ["789hdgr4ad589a9bdafa4e3b"]

export function convertToHtml(str) {
  const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
  str = str.replace(regex, '<br>');
  return str;
}

export function convertToMessage(str) {
  if(str == sendingLimitText) return messagesLimitText;
  if(str == askAgainText) return askAgainTextMarkdown;
  return str;
}

export function convertMsgToSend(str, forAI=false) {
  if (str === askAgainText) {
    return "Ask again.";
  }
  if (str === sendingLimitText || str === messageOnlyForPro) {
    return "No answer.";
  }
  // retun trim string with a limit of 200 characters
  let newStr = str.trim();
  if (newStr.length > 150 && forAI) {
    newStr = newStr.substring(0, 150) + "...";
  }
  else if (newStr.length >2000) {
    newStr = newStr.substring(0, 2000);
  }
  return newStr;
}

export function getTextPart(str = "") {
  if (str.search("```") < 0) return [{ content: str, isCode: false }]
  if (str.search("```") == 0) return [{ content: str.split("```")[1], isCode: true }]
  return [{ content: str.split("```")[0], isCode: false }, { content: str.split("```")[1], isCode: true }, { content: str.split("```")[2], isCode: false }]
}

export const convertDataToAppWriteMsg = (data) => {
  let newM = {
    conversationId: data.conversationId,
    text: data.text,
    time: data.time,
    isAuthorAi: data.isAuthorAi,
    authorId: data.authorId,
    aiauthor: data.aiauthor,
  };

  return newM
}

export const convertDataToAiWriteMsg = (data) => {
  let newM = {
    conversationId: data.conversationId,
    text: data.text,
    time: new Date().toISOString(),
    isAuthorAi: true,
    authorId: null,
    aiauthor: data.aiauthor,
    kapycoins: data.kapycoins,
  };

  return newM
}

export const DataToMsgWriting = () => {
  let newM = {
    conversationId: uniqid(),
    text: writingAiText,
    time: new Date().toISOString(),
    isAuthorAi: true,
    authorId: null,
    aiauthor: "gpt4",
  };

  return newM
}


export const convertDataToMsg = (data) => {
  let newM = {
    mId: uniqid(),
    text: data.text && data.text,
    time: data.time,
    meta: {
      ...data.meta,
      sent: true,
      received: true,
      read: true,
    },
  };
  if (data.image && data.image.length) {
    newM["image"] = data.image;
  }
  if (data.attachments && data.attachments.length) {
    newM["attachments"] = data.attachments;
  }
  if (data.replyOf) {
    newM["replyOf"] = data.replyOf;
  }
  return newM
}

export const convertDataToMsgWriting = (data) => {
  let newM = {
    mId: uniqid(),
    text: writingAiText,
    time: data.time,
    kapycoins:0,
    meta: {
      ...data.meta,
      receiver: data.meta.sender,
      sender: data.meta.receiver,
      sent: true,
      received: true,
      read: true,
    },
  };
  if (data.image && data.image.length) {
    newM["image"] = data.image;
  }
  if (data.attachments && data.attachments.length) {
    newM["attachments"] = data.attachments;
  }
  if (data.replyOf) {
    newM["replyOf"] = data.replyOf;
  }
  return newM
}

// get country code with http request to ipinfo.io/195.37.234.237?token=408640597e432a
export const getLocationInfo = () => {
  return new Promise((resolve, reject) => {
    //first check if country code is already set in local storage
    let locationInfo = JSON.parse(localStorage.getItem('locationInfo'));
    if (locationInfo) {
      //setcountry(countryCode);
      resolve(locationInfo);
    }
    else {
      //if not, get it from ipinfo.io and set it in local storage
      fetch('https://ipinfo.io?token=6ac716e5339265') //408640597e432a')
        .then((response) => response.json())
        .then((data) => {
          //setcountry(data.country)
          locationInfo = data;
          localStorage.setItem('locationInfo', JSON.stringify(data));
          resolve(locationInfo);
        })
        .catch((error) => {
          //console.log(error);
        });

      resolve(locationInfo);
    }

  });
}

export const uniqidGenerator = () => {
  return uniqid() + Math.floor(Math.random() * 100000000).toString();
}