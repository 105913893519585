export const PaymentTypes = [
    {
        id: 'cinetpay',
        name: 'Mobile Money central and west Africa',
        apikey: 'xxxxxxxxxxxxx',
        siteId: 'xxxxxxxxxxxxx',
        notifyUrl: 'https://xxxxxxxxxxxxx',
        mode: 'PRODUCTION',
        channels: 'ALL',
        desciption: 'Mobile money central and west Africa '
    },
    {
        id: 'paypal',
        name: 'Paypal, Visa, Mastercard, Bank transfer Worldwide',
        apikey: 'xxxxxxxxxxxxx',
        siteId: 'xxxxxxxxxxxxx',
        notifyUrl: 'https://xxxxxxxxxxxxx',
        mode: 'PRODUCTION',
        channels: 'ALL',
        desciption: 'Worldwide payment'
    }
]

export const PackageTypes = [
    {
        id: "300kapycoins",
        name: "300 KapyCoins",
        kapycoins: 300,
        EuroPrice: 2,
        prices: [
            {
                id: "XAF",
                value: 1000
            },
            {
                id: "XOF",
                value: 1000
            },
            {
                id:"GNF",
                value: 1000*14.5,
            },
            {
                id:"CDF",
                value: 1000*3.5,
            },
            {
                id:"USD",
                value: 2
            },
        ]
    },
    {
        id: "1000kapycoins",
        name: "1000 KapyCoins",
        kapycoins: 1000,
        EuroPrice: 5,
        prices: [
            {
                id: "XAF",
                value: 3000
            },
            {
                id: "XOF",
                value: 3000
            },
            {
                id:"GNF",
                value: 3000*14.5,
            },
            {
                id:"CDF",
                value: 3000*3.5,
            },
            {
                id:"USD",
                value: 5
            },
        ]
    },
    {
        id: "2000kapycoins",
        name: "2000 KapyCoins",
        kapycoins: 2000,
        EuroPrice: 9,
        prices: [
            {
                id: "XAF",
                value: 5000
            },
            {
                id: "XOF",
                value: 5000
            },
            {
                id:"GNF",
                value: 5000*14.5,
            },
            {
                id:"CDF",
                value: 5000*3.5,
            },
            {
                id:"USD",
                value: 9
            },
        ]
    },
    {
        id: "5000kapycoins",
        name: "5000 KapyCoins",
        kapycoins: 5000,
        EuroPrice: 20,
        prices: [
            {
                id: "XAF",
                value: 10000
            },
            {
                id: "XOF",
                value: 10000
            },
            {
                id:"GNF",
                value: 10000*14.5,
            },
            {
                id:"CDF",
                value: 10000*3.5,
            },
            {
                id:"USD",
                value: 20
            },
        ]
    },
]

export const Countries = [
    {
        id: "CM",
        name: "Cameroon",
        currency: "XAF",
        currencySymbol: "FCFA",
    },
    {
        id: "CI",
        name: "Cote d'Ivoire",
        currency: "XOF",
        currencySymbol: "XOF",
    },
    {
        id: "GN",
        name: "Guinea",
        currency: "GNF",
        currencySymbol: "GNF",
    },
    {
        id: "CD",
        name: "DR Congo",
        currency: "CDF",
        currencySymbol: "CDF",
    },
    {
        id: "CD-USD",
        name: "DR Congo",
        currency: "USD",
        currencySymbol: "USD",
    },
    {
        id: "ML",
        name: "Mali",
        currency: "XOF",
        currencySymbol: "XOF",
    },
    {
        id: "SN",
        name: "Senegal",
        currency: "XOF",
        currencySymbol: "XOF",
    },
    {
        id: "TG",
        name: "Togo",
        currency: "XOF",
        currencySymbol: "XOF",
    },
    {
        id: "BF",
        name: "Burkina Faso",
        currency: "XOF",
        currencySymbol: "XOF",
    },
    {
        id: "BJ",
        name: "Benin",
        currency: "XOF",
        currencySymbol: "XOF",

    },
]

