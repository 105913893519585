import React, { useEffect, useState } from 'react'
import { useScript } from './UseScript';
import { FormGroup, Input, Label } from 'reactstrap';
import { Countries, PackageTypes, PaymentTypes } from '../data/payments';
import { uniqidGenerator } from '../helpers/utils';
import { PayPalButtons } from "@paypal/react-paypal-js";
import toast from 'react-hot-toast';
import { getAppWriteBackend } from '../helpers/appwrite/appwrite_helper';
import { useProfile } from '../hooks';

function Payment() {
  const { CinetPay }: any = useScript('https://cdn.cinetpay.com/seamless/main.js', 'CinetPay');
  const [Package, setPackage] = useState(PackageTypes[0]);
  const [Country, setCountry] = useState(Countries[0]);
  const [showPaymentType, setShowPaymentType] = useState(false);
  const [paymentType, setPaymentType] = useState(PaymentTypes[0]);
  const [showPayment, setShowPayment] = useState(false);
  const [displayPayPal, setDisplayPayPal] = useState(false);
  const {userProfile} = useProfile();

  const appwritebackend = getAppWriteBackend();

  useEffect(() => {
    if (CinetPay) {
      CinetPay.setConfig({
        apikey: '188256831963efb06b87c291.69002942',
        site_id: "298667",
        mode: 'PRODUCTION',
        notify_url: process.env.REACT_APP_PAY_NOTIFY_URL
      });
      //console.log(CinetPay);
    }
  }, [CinetPay])

  const pay = (e) => {
    CinetPay.getCheckout({
      transaction_id: uniqidGenerator(),
      amount: Package.prices.find(price => price.id == Country.currency).value,
      currency: Country.currency,
      channels: 'ALL',
      description: Package.name + ' - ' + Country.name + ' - ' + userProfile.email + ' - ' + userProfile.$id,
      // Fournir ces variables obligatoirement pour le paiements par carte bancaire
      // customer_name:"Joe",//Le nom du client
      // customer_surname:"Down",//Le prenom du client
      // customer_email: "down@test.com",//l'email du client
      // customer_phone_number: "088767611",//l'email du client
      // customer_address : "BP 0024",//addresse du client
      // customer_city: "Antananarivo",// La ville du client
      // customer_country : "CM",// le code ISO du pays
      // customer_state : "CM",// le code ISO l'état
      // customer_zip_code : "06510", // code postal
    });
    
    CinetPay.waitResponse(function(data) {

      console.log("PAY: payments INFO:", data);
     
      // En cas d'échec
       if (data.status == "REFUSED") {
            onError(data);
       }
       // En cas de succès
       else if (data.status == "ACCEPTED") {
          addKapyCoinsToUser();
       }
});

  }

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: Package.EuroPrice,
            },
          },
        ],
      })
      .then((orderId) => {
        // Your code here after create the order
        return orderId;
      });
  }

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function () {
      // Your code here after capture the order
      console.log("PAY: Order completed");
      addKapyCoinsToUser();
    });
  }


  const addKapyCoinsToUser = () => {
    console.log("PAY: Payment success add to db");
    appwritebackend.addPaymentToUser(Package, paymentType, "success");
    toast('Good Job! you have +' + Package.name + ".", {
      icon: '👏',
      duration: 10000,
    });
    setShowPaymentType(false);
    setShowPayment(false);
    setDisplayPayPal(false);
  }

  const onError = (err) => {
    console.log(err);
    appwritebackend.addPaymentToUser(Package, paymentType, "error");
    toast.error('Something went wrong, please try again.', {
      duration: 10000,
      });
  }


  return (
    <div >
      {showPaymentType && !showPayment && <div>
        <FormGroup>
          <Label for="exampleSelect">
            Choose your Payment Type
          </Label>
          <Input
            id="exampleSelect"
            name="select"
            type="select"
            onChange={(e) => setPaymentType(PaymentTypes.find(item => item.id == e.target.value))}
            value={paymentType.id}
          >
            {PaymentTypes.map((item, index) => (
              <option key={index} value={item.id}> {item.name} </option>
            ))}
          </Input>
        </FormGroup>
        <br />
        <FormGroup>
          <button className='btn btn-primary' onClick={() => setShowPayment(true)}>Next</button>
        </FormGroup>
      </div>}

      {showPayment && paymentType.id == "cinetpay" && <div>
        <FormGroup>
          <Label for="exampleSelect">
            Choose your Pack
          </Label>
          <Input
            id="PackageTypes"
            name="select"
            type="select"
            onChange={(e) => setPackage(PackageTypes.find(item => item.id == e.target.value))}
            value={Package.id}
          >
            {PackageTypes.map((item, index) => (
              <option key={index} value={item.id}> {item.name} ({item.prices.find(price => price.id == Country.currency).value} {Country.currencySymbol}) </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="exampleSelect">
            Select your Country
          </Label>
          <Input
            id="exampleSelect"
            name="select"
            type="select"
            onChange={(e) => setCountry(Countries.find(item => item.id == e.target.value))}
            value={Country.id}
          >
            {Countries.map((item, index) => (
              <option key={index} value={item.id}> {item.name} ({item.currencySymbol}) </option>
            ))}
          </Input>
        </FormGroup>
        <br />
        <FormGroup>
          <button className='btn btn-primary' onClick={pay}>Finish Paymnent</button>
        </FormGroup>
      </div>}

      {showPayment && paymentType.id == "paypal" && <div>
        <FormGroup>
          <Label for="exampleSelect">
            Choose your Pack
          </Label>
          <Input
            id="PackageTypes"
            name="select"
            type="select"
            onChange={(e) => setPackage(PackageTypes.find(item => item.id == e.target.value))}
            value={Package.id}
          >
            {PackageTypes.map((item, index) => (
              <option key={index} value={item.id}> {item.name} ({item.EuroPrice} EUR) </option>
            ))}
          </Input>
        </FormGroup>
        <br />
        <FormGroup>
          <button className='btn btn-primary' onClick={() => {
            setShowPayment(false);
            setShowPaymentType(false);
            setDisplayPayPal(true);
          }}>Finish Paymnent</button>
        </FormGroup>
      </div>}

      {displayPayPal && <div>
        <span>Finish Payment:</span>

          <PayPalButtons
            style={{ layout: 'vertical' }}
            createOrder={(data, actions) => createOrder(data, actions)}
            onApprove={(data, actions) => onApprove(data, actions)}
            onError={(err) => onError(err)}
          />
       
      </div>}



      {!showPaymentType && !showPayment && !displayPayPal && <a href='#' onClick={() => { setShowPaymentType(true); }}> Add KapyCoins for more Chat and Power</a>}
    </div>
  )
}

export default Payment