export async function downloadFromURL(imageSrc:string) {
    const image = await fetch(imageSrc)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
  
    const link = document.createElement('a')
    link.href = imageURL
    link.download = 'kapygpt_generated_image'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }